// the okta domain and client id are defined in the .env files for each environment

export default {
    auth: {
        oktaDomain: process.env.REACT_APP_OKTA_DOMAIN,
        clientId: process.env.REACT_APP_CLIENT_ID,
        callbackPath: '/implicit/callback',
        issuer: `https://${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/default`,
        host: window.location.host,
        redirectUri: `${window.location.origin}/implicit/callback`,
        scopes: 'openid profile email'.split(/\s+/), // TODO: determine what other scopes are available or which ones we need
        grantType:  'authorization_code'
    },
    site: {
        name: 'Vantage'
    },
    tableau: {
        url: process.env.REACT_APP_TABLEAU_URL,
        siteRoot: process.env.REACT_APP_TABLEAU_SITEROOT,
        storyMakerMap: process.env.REACT_APP_TABLEAU_MAP_URL,
        storyMakerMapInstructions: process.env.REACT_APP_TABLEAU_MAP_INSTRUCTIONS_URL,
        jsLibUrl: "https://public.tableau.com/javascripts/api/tableau.embedding.3.5.0.min.js"
    },
    googleAnalytics: {
        siteId: process.env.REACT_APP_GOOGLE_ANALYTICS_CODE
    },
    discAssessment: {
        discAssessmentInstructions: "https://storymakercentral.omceg.com/document_item/disc-working-effectively/disc-handout-working-effectively-2/"
    }
}
