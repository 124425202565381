import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import QuestionMarkIcon from '@material-ui/icons/Help';

import config from '../../config';
import ReportService from '../../core/services/ReportService';
import ErrorDisplay from '../../components/error/Error';

import { useError } from '../../hooks/useError';
import useScript from '../../hooks/useScript';

import './style.scss';

export default function StoryMakerMap() {
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState(null);
    const [errorObj, setError] = useError();

    useScript(config.tableau.jsLibUrl);

    useEffect(() => {
        setIsLoading(true);

        ReportService.generateAuthToken().then(response => {
            setToken(response.token);
            setIsLoading(false);
        }).catch(e => {
            setError(true, e, "Tableau authorization failed.");
        });
    }, []);

    function renderStoryMakerMap(jwtToken) {
        return (
            <div className='tableauPlaceholder'>
                <tableau-viz id="tableauViz"
                    src={config.tableau.storyMakerMap}
                    toolbar="hidden" 
                    token={jwtToken}>
                </tableau-viz>
            </div>
        );
    }

    return (
        <div>
            <h3>StoryMaker Map</h3>

            <a class="help" tabindex="0" aria-disabled="false" href={config.tableau.storyMakerMapInstructions} target="_blank">
                <span class="icon"><QuestionMarkIcon style={{ fontSize: 24 }}/></span>
                <span class="info">MAP INSTRUCTIONS</span>
            </a>
            
            { isLoading && !errorObj.error ? <p>Loading...</p> : null }

            { !errorObj.error ? renderStoryMakerMap(token) : <ErrorDisplay code={errorObj.code} message={errorObj.message} detail={errorObj.detail} />}
        </div>
    );
}
