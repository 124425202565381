import React, { useEffect, useState } from 'react';

import { Modal, Fade } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconEmail from '@material-ui/icons/Email';
import IconMobilePhone from '@material-ui/icons/PhoneAndroid';
import IconOfficePhone from '@material-ui/icons/Phone';
import IconWork from '@material-ui/icons/Work';
import IconSupervisor from '@material-ui/icons/SupervisorAccount';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import IconLabel from '@material-ui/icons/Label';
import IconSave from '@material-ui/icons/Save';
import IconExport from '@material-ui/icons/SaveAlt';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../components/tabs/TabPanel';

import EmployeeService from '../../core/services/EmployeeService';
import ProfileQuestionService from '../../core/services/ProfileQuestionService';
import ErrorDisplay from '../../components/error/Error';

import { formatPhoneNumber, formatDate, formatName } from '../../core/utils';
import { useSnackbar } from 'notistack';
import { useError } from '../../hooks/useError';
import './style.scss';

import SignatureGenerator from '../../components/signatureGenerator/SignatureGenerator';
import PronounSelector from '../../components/user/PronounSelector';

import ProfilePhoto from '../../components/profilePhoto/ProfilePhoto';
import ProfilePhotoEditor from '../../components/profilePhoto/ProfilePhotoEditor';
import ManagedEmployees from '../../components/employee/ManagedEmployees';

import { createResponsesObject, getTopAweProfileGroups, loadAweProfile } from '../../core/aweProfileHelpers';
import { loadEmployee } from '../../core/employeeProfileHelper';
import AnyplaceWorkplaceSurvey from '../../components/workplaceExperience/SurveyForm';
import ProfileContextNav from '../../components/employee/ProfileContextNav';

export default function Profile(props) {
  const { enqueueSnackbar } = useSnackbar();

  const [emp, setEmp] = useState({});
  const [managedEmployees, setManagedEmployees] = useState([]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pronouns, setPronouns] = useState('');
  const [empBio, setEmpBio] = useState('');
  const [errorObj, setError] = useError();
  const [isSaving, setIsSaving] = useState(false);

  const [isPhotoUploadOpen, setIsPhotoUploadOpen] = useState(false);
  const [isSignatureGeneratorOpen, setIsSignatureGeneratorOpen] = useState(false);

  const [validationErrors,  setValidationErrors] = useState([]);

  const [workplaceSurveyTopLevelGroups, setWorkplaceSurveyTopLevelGroups] = useState([]);
  const [workplaceSurveyGroups, setWorkplaceSurveyGroups] = useState([]);
  const [workplaceSurveyQuestions, setWorkplaceSurveyQuestions] = useState([]);
  const [workplaceSurveyResponses, setWorkplaceSurveyResponses] = useState({});

  // on load get our employee
  useEffect(() => {
    setIsLoading(true);

    loadEmployee().then((result) => {
      setEmp(result.employee);
      setEmpBio(result.employee.bio);
      setPronouns(result.employee.preferredPronoun);
      setManagedEmployees(result.managedEmps);
      setIsLoading(false);
    }).catch(_ => {
      enqueueSnackbar('There was an error loading the Employee info.', { 'variant': 'error' });
      setIsLoading(false);
    });
    
    // VAN-99 move the AWE retrieve methods here to make validation easier
    loadAweProfile().then((result) => {
      setWorkplaceSurveyGroups(result.groups);
      setWorkplaceSurveyQuestions(result.questions);

      const responseObj = createResponsesObject(result.responses);
      setWorkplaceSurveyResponses(responseObj);

      // Need the top level groups for the side context nav
      let topLevelGroups = getTopAweProfileGroups(result.groups);
      setWorkplaceSurveyTopLevelGroups(topLevelGroups);
    }).catch(_ => {
      enqueueSnackbar('There was an error loading the Workplace profile.', { 'variant': 'error' });
    });
  }, []);

  const handleBioChange = (e) => {
    setEmpBio(e.target.value);
  };

  const handlePronounsChange = (value) => {
    setPronouns(value);
  };

  const openPhotoUpload = () => {
    setIsPhotoUploadOpen(true);
  };

  const openSignatureGenerator = () => {
    setIsSignatureGeneratorOpen(true);
  };

  const handlePhotoUploadClose = (imageWasChanged, reason) => {
    if (reason !== 'backdropClick') {
      setIsPhotoUploadOpen(false)
    }

    if (imageWasChanged && reason !== 'backdropClick') {
      // Forcing a refresh to make sure everything is updated
      window.location.reload();      
    }
  };

  const handleSignatureGeneratorClose = () => {
    setIsSignatureGeneratorOpen(false)
  };

  const handleUpdatedWorkplaceSurveyResponses = (responses, errors) => {
    setWorkplaceSurveyResponses(responses);

    if (errors) {
      setValidationErrors(errors);
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
  };

  const a11yProps = (index) => {
      return {
          id: `full-width-tab-${index}`,
          'aria-controls': `full-width-tabpanel-${index}`,
      };
  }

  const validateSurveyResponses = () => {
    const errors = [];

    const requiredQuestions = workplaceSurveyQuestions.filter(x => x.isRequired);
    requiredQuestions.forEach(question => {
      const validationErrorMsg = `"${question.questionText}" is required.`;

      const response = workplaceSurveyResponses[question.id];
      
      if (!response || !response.responseValue) {
        // If there's no response or response vaue, then question is invalid
        errors.push(validationErrorMsg);
      } else if (response && question.allowManualInput) {
        // If a response is found, and the question allows manual input, 
        // then we need to get the value of the 'manualInputOption' and compare it against the response's value
        // if it matches the default, then the question is considered invalid.
        const manualInputOption = question.options.find(x => x.showManualInput);
        if (manualInputOption && response.responseValue === manualInputOption.optionValue) {
            errors.push(validationErrorMsg);        
        }
      }
    });

    return errors;
  };

  const save = () => {
    setIsSaving(true);

    const validationErrors = validateSurveyResponses();
    setValidationErrors(validationErrors);

    // If there are any validation errors, don't save
    if (validationErrors.length > 0) {
      setIsSaving(false);
      return;
    }

    Promise.all([saveProfile(), saveWorkplaceSurvey()]).then(() => {
      enqueueSnackbar(`Profile saved`, { 'variant': 'success' });
      props.history.push('/directory'); // redirect to directory after save
      window.scrollTo(0, 0)
    }).catch(e => {
      setIsSaving(false);
      enqueueSnackbar(`Error saving profile`, { 'variant': 'error' });
      console.error(e);
    })
  }

  const saveProfile = async () => {
    const profile = {
      // User ID is set on the backend based on current user
      "personId": emp.personId,
      "bio": empBio, // in local state
      "preferredPronoun": pronouns ? pronouns.trim() : "" // the value (starts as null in the db)
    }

    return EmployeeService.saveProfile(profile);
  };

  const saveWorkplaceSurvey = async () => {
    // Convert the object back to an array to be saved
    const responses = Object.values(workplaceSurveyResponses);

    // Only make the PUT request if there are responses to save
    if (responses.length > 0) {
      const updateResponse = await ProfileQuestionService.updateResponses(2, responses);
      const updatedResponseObj = createResponsesObject(updateResponse);

      // After saving, we want to update state to ensure we have the new response IDs
      // Otherwise, saving future updates (without a page refresh) will result in duplicates
      setWorkplaceSurveyResponses(updatedResponseObj);
    }
  };

  const generateSpreadsheet = () => {
    EmployeeService.generateAweResponsesSpreadsheet().then(response => {
      enqueueSnackbar('Your export file has been generated. You should receive an email within the next 10 minutes.', { 'variant': 'success', 'persist': true });
    }).catch(e => {
        setError(true, e, "An error occurred generating the AWE Responses spreadsheet.");
        enqueueSnackbar('There was an error loading the Workplace profile.', { 'variant': 'error', 'persist': true });
    });
  };

  return (

    <section className="card">
      { !errorObj.error ?
        <article style={{ margin: 0, paddingBottom: 0 }}>
          <h1 style={{ marginBottom: 0 }}>Your Profile</h1>
        </article>
      : null}

      { errorObj.error ?
          <ErrorDisplay code={errorObj.code} message={errorObj.message} detail={errorObj.detail} />
      :
      <article>
        {/* Loading Mask */}
        <Backdrop className="backdrop" open={isLoading}>
          <CircularProgress />
        </Backdrop>

        {isLoading ?
          null
          :
          <div className="profileColumns">
            <div className="profileCol">
              <div className="photoWrapper">
                <div className="profilePhoto">
                  <Button className="changePhotoButton" variant="text" title="Change your profile photo" onClick={openPhotoUpload}>
                    <AddAPhotoIcon />
                  </Button>
                  <ProfilePhoto employee={emp} forceRefresh={true} />
                </div>
              </div>

              <h2>{formatName(emp)}</h2>
              <p className="title">{emp.title}</p>
              <br/>
              <p className="department">{emp.department}</p>
              <p className="company">{emp.agencyDisplayName}</p>         

              <ProfileContextNav groups={workplaceSurveyTopLevelGroups} />
            </div>

            <div className="profileCol">
              <AppBar position="static" color="default" style={{marginBottom: 20}}>
                  <Tabs
                      value={currentTabIndex}
                      onChange={handleTabChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      aria-label="employee profile tabs"
                  >
                      <Tab label="Details" {...a11yProps(0)} />
                      {managedEmployees && managedEmployees.length > 0 ? <Tab label="Managing" {...a11yProps(2)} /> : null}

                  </Tabs>
              </AppBar>

              <TabPanel value={currentTabIndex} index={0} className="tab-content">    
                <div id="section-contact-info">
                  <h3 style={{ margin: '0 0 20px 0' }}>
                    <IconLabel className="group-icon" /> Contact Info
                  </h3>

                  <p><em><IconEmail /></em> {emp.emailAddress}</p>
                  {emp.mobilePhone ? <p><em><IconMobilePhone /></em> <span className="meta-label">Mobile:</span> <a href={"tel:" + formatPhoneNumber(emp.mobilePhone)}>{formatPhoneNumber(emp.mobilePhone)}</a></p> : '' }
                  {emp.officePhone ? <p><em><IconOfficePhone /></em> <span className="meta-label">Desk:</span> <a href={"tel:" + formatPhoneNumber(emp.officePhone)}>{formatPhoneNumber(emp.officePhone)}</a></p> : '' }
                  
                  <p className="formButtons">
                  <Button 
                      variant="contained" 
                      color="primary" 
                      disabled={false}
                      onClick={openSignatureGenerator}
                      >
                      <BorderColorIcon />   Generate Email Signature
                  </Button>
                  </p>

                  <h3 id="section-about" style={{margin: '60px 0 20px 0'}}>
                    <IconLabel className="group-icon" /> About You
                  </h3>

                  <p><em><IconSupervisor /></em> <span className="meta-label">Manager:</span> {emp.supervisorName}</p>
                  <p><em><IconWork /></em> <span className="meta-label">Hire Date:</span> {formatDate(emp.hireDate)}</p>

                  <br />

                  <PronounSelector pronouns={pronouns} onPronounsChanged={(value) => handlePronounsChange(value)} />

                  <TextField
                    className="bio"
                    variant="outlined"
                    label="Bio"
                    helperText={empBio ? '' : 'A bio is optional, but it\'d be pretty great if you added one.'}
                    style={{ width: `100%`, maxWidth: 500 }}
                    multiline={true}
                    minRows="6"
                    value={empBio ? empBio : ''}
                    onChange={handleBioChange}
                  />

                  <AnyplaceWorkplaceSurvey 
                    onResponsesChanged={(responses, validationErrors) => 
                        handleUpdatedWorkplaceSurveyResponses(responses, validationErrors)}
                    workplaceSurveyGroups={workplaceSurveyGroups}
                    workplaceSurveyQuestions={workplaceSurveyQuestions}
                    workplaceSurveyResponses={workplaceSurveyResponses}
                    workplaceSurveyTopLevelGroups={workplaceSurveyTopLevelGroups}
                    showLocationDetails={emp.shareLocationDetails}
                    readOnly={false}
                  />

                  <hr />

                  { validationErrors.length > 0 ? 
                      <div className="error">
                          There are some errors with your profile. Please review the errors below:
                          <ul>
                              {validationErrors.map((x, idx) => (<li key={idx}>{x}</li>))}
                          </ul>
                      </div>
                    : null
                  }

                  <p className="formButtons">
                    <Button 
                      variant="contained" 
                      color="primary" 
                      disabled={isSaving}
                      onClick={save}
                      >
                      <IconSave /> Save Changes
                    </Button>

                    <Button 
                      variant="text" 
                      href="/directory"
                      disabled={isSaving}
                      >
                      Cancel
                    </Button>
                  </p>
                </div>
              </TabPanel>

              { managedEmployees && managedEmployees.length > 0 ?
                  <TabPanel value={currentTabIndex} index={1} className="tab-content">
                      <ManagedEmployees managedEmps={managedEmployees} />

                      <div className="profile-export">   
                        <Button variant="contained" color="primary" title="Export Profiles for your Team as an Excel file" onClick={generateSpreadsheet}><IconExport /> Export Your Team Profiles</Button>
                      </div>
                  </TabPanel>
                  : null
              }
            </div>

            <Modal
              open={isPhotoUploadOpen}
              onClose={handlePhotoUploadClose}
            >
              <Fade in={isPhotoUploadOpen}>
                <div>
                  <ProfilePhotoEditor handleClose={handlePhotoUploadClose} />
                </div>
              </Fade>
            </Modal>

            <Modal
              open={isSignatureGeneratorOpen}
              onClose={handleSignatureGeneratorClose}
            >
              <Fade in={isSignatureGeneratorOpen}>
                <div>
                  <SignatureGenerator handleClose={handleSignatureGeneratorClose} employee={emp} />
                </div>
              </Fade>
            </Modal>
          </div>
        }

      </article>
      }
    </section>

  );

}
