import { useEffect } from 'react';

export default function useScript(resourceUrl) {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = resourceUrl;
    script.type = 'module';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [resourceUrl]);
}