import React, { useState, useEffect } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';

import LoginForm from '../../components/auth/LoginForm';
import MfaVerify from '../../components/auth/MfaVerify';

import config from '../../config';

import './style.scss';

function Login() {
	const history = useHistory();
	const { oktaAuth, authState } = useOktaAuth();
	
	const [authError, setAuthError] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	// MFA state
	const [ isMfaRequired, setIsMfaRequired ] = useState(false);
	const [ mfaFactors, setMfaFactors ] = useState([]);

	const handleLoginSubmit = (user, pass) => {
		if (!user || !pass) {
			setAuthError('Email/Username and Password are required.');
			return;
		}

		setIsLoading(true);

		oktaAuth.signIn({
			username: user,
			password: pass,
		})
		.then(authResponse => {
			setAuthError('');
			setIsLoading(false);

			if (authResponse.status === 'MFA_REQUIRED') {
				setIsMfaRequired(true);
				setMfaFactors(authResponse.factors);
			} else if (authResponse.status === 'MFA_ENROLL') {
				setAuthError('Your account requires MFA and is not currently enrolled. '
					+ 'Please go to Okta to enroll in MFA to continue logging in.');
			} else {
				// No MFA required
				completeAuthentication(authResponse.sessionToken);
			}
		})
		.catch(err => {
			// console.log('authn error', err);
			setIsLoading(false);
			
			if (err && err.errorCode === 'E0000004') {
				setAuthError('Email/Username or Password is not correct.');
			} else if (err && err.errorCauses && err.errorCauses.length > 0) {
				setAuthError(err.errorCauses[0].errorSummary);
			} else {
				setAuthError('There was an error authenticating you. Please try again.');
			}
		});
	};

	const completeAuthentication = (sessionToken) => {
		oktaAuth.token.getWithoutPrompt({
			responseType: 'id_token',
			sessionToken: sessionToken,
			scopes: config.auth.scopes,			
		}).then(tokenResponse => {
			setAuthError('');
			setIsLoading(false);

			oktaAuth.tokenManager.add('idToken', tokenResponse.tokens.idToken);
			oktaAuth.tokenManager.add('accessToken', tokenResponse.tokens.accessToken);

			history.push('/'); // success, redirect
		}).catch(tokenErr => {
			setIsLoading(false);

			if (tokenErr && tokenErr.errorCode === 'access_denied') {
				setAuthError('You do not have permission to access this '
				+ 'application. Please contact itapplicationsupport@gmrmarketing.com '
				+ 'for assistance.');
			} else {
				setAuthError('There was an error authenticating you. Please try again.');
			}
		});
	}

	const handleMfaVerified = (mfaResponse) => {
		completeAuthentication(mfaResponse.sessionToken);
	};

	// If we're already authenticated go to the home page (i.e. not the directory anymore)
	useEffect(() => {
		if (authState && authState.isAuthenticated) {
			history.push('/');
		}
	});

	return (
		<div>
			{/* Loading Mask */}
			<Backdrop className="backdrop" open={isLoading}>
				<CircularProgress />
			</Backdrop>

			<section className="login">
				<article>
					<h1><span>{config.site.name}</span></h1>

					{authError ? (<p className="auth-error">{authError}</p>) : null}

					{ !isMfaRequired && 
						<LoginForm
							onSubmitLogin={(user, pass) => handleLoginSubmit(user, pass)}
						/>
					}

					{ isMfaRequired && 
						<MfaVerify
							availableFactors={mfaFactors}
							onVerified={(response) => handleMfaVerified(response)}
						/>
					}
				</article>

			</section>
      		
			<div className="brand-stripe"></div>      
		</div>
	);
}
export default Login;